import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import React, {useState} from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {TEXT_BUTTON_PRIORITY, TextButton} from 'wix-ui-tpa/TextButton'
import {ChevronUp, ChevronDown} from 'wix-ui-icons-common'
import classNames from 'classnames'
import {DH} from '../../../data-hooks'
import settingsParams from '../../../settingsParams'
import {useWidgetProps} from '../../../hooks/widget-props'
import {useColors} from '../../../hooks/colors'
import {useModal} from '../../../hooks/modal'
import {classes as c} from './description.st.css'
import {DescriptionProps} from '.'

export const Description = ({text, itemId}: DescriptionProps) => {
  const {insideModal} = useModal()
  const {readDescriptionClicked} = useWidgetProps()
  const [opened, toggle] = useState(false)
  const settings = useSettings()
  const {isDarkActionColor} = useColors()
  const readMoreText = settings.get(settingsParams.readMore)
  const readLessText = settings.get(settingsParams.readLess)

  const wired = isDarkActionColor()

  const handleReadMore = () => {
    const newOpened = !opened

    if (newOpened) {
      readDescriptionClicked({itemId})
    }

    toggle(newOpened)
  }

  return (
    <div className={c.container}>
      <TextButton
        className={classNames(c.button, {
          [c.blackButton]: insideModal && !wired,
          [c.defaultButton]: !insideModal || wired,
        })}
        priority={TEXT_BUTTON_PRIORITY.primary}
        data-hook={DH.DESCRIPTION_BUTTON}
        onClick={handleReadMore}
        suffixIcon={opened ? <ChevronUp /> : <ChevronDown />}
        aria-expanded={opened}
      >
        {opened ? readLessText : readMoreText}
      </TextButton>
      {opened && (
        <Text
          className={classNames(c.text, {[c.defaultText]: !insideModal, [c.blackText]: insideModal})}
          data-hook={DH.DESCRIPTION}
        >
          <div dangerouslySetInnerHTML={{__html: text}} />
        </Text>
      )}
    </div>
  )
}
